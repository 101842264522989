<template>
  <v-app>
    <div class="row">
      <v-overlay :value="isDeleting">
        <v-progress-circular
            indeterminate
            color="dark"
        ></v-progress-circular>
      </v-overlay>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Pending invoices</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>
                  \ Pending invoices
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>

                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="exportToCsv">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="exportToExcel">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                  </b-dropdown>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Invoice no." v-model="search.info" @input="search.info = $event !== null ? $event : ''" outlined clearable v-on:keyup.enter="searchPendingInvoice" dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select outlined dense label="Status" :items="status" item-text="text" item-value="value" v-on:keyup.enter="searchPendingInvoice" v-model="search.payment_status"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select outlined dense label="Score" :items="scores" @input="search.score_id = $event !== null ? $event : ''" item-text="name" clearable item-value="id" v-on:keyup.enter="searchPendingInvoice" v-model="search.score_id"></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="text-right" md="3">
                  <v-btn
                      @click="searchPendingInvoice"
                      class="btn btn-primary"
                      style="color: #fff;"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoice no.</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Order placed date</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Amount</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enroller</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">School name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoiced to</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">PO number</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in pending_invoices">
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span  class="">
                        {{ item.invoice_id }}
                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span  class="">
                        {{ item.order_placed_timestamp_format ? item.order_placed_timestamp_format : '-'  }}
                      </span>
                    </td>
<!--                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">-->
<!--                      <span  class="">-->
<!--                        {{ item.formatted_order_paid ? item.formatted_order_paid : '-' }}-->
<!--                      </span>-->
<!--                    </td>-->
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">

                        {{item.currency_symbol ? item.currency_symbol : '$' }}{{item.amount_paid ? item.amount_paid : 'NA' }}

                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="">
                        {{ item.user_full_name}}
                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="">
                        {{ item.school_name}}
                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="">
                        {{ item.invoiced_to ? item.invoice_to_user_name : ''}}<br/>
                        {{ item.invoiced_address ? item.invoiced_address : ''}}
                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="">
                        {{ item.po_number ? item.po_number : '-'}}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="badge" :class="item.payment_status=='pending' ? 'badge-warning':'badge-danger'">
                        {{ item.payment_status ? item.payment_status_text : '-'}}
                      </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewInvoice(item.user_id,item.id)" class="navi-link" v-if="item.can_view_invoice">
                                <span class="navi-icon">
                                    <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">View Invoice</span>
                              </a>
                              <a class="navi-link" v-if="!item.can_view_invoice">
                                <span class="navi-icon">
                                    <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text"><strike>View Invoice</strike></span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="approvedCart(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="navi-text">Mark as paid </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="rejectCart(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-ban</v-icon>
                                </span>
                                <span class="navi-text">Mark as not approve</span>

                              </a>
                            </b-dropdown-text>

                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="pending_invoices.length == 0">
                    <td colspan="8" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="pending_invoices.length > 0"
                  class="pull-right mt-7"
                  @input="getAllPendingCart"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
      <approved-or-rejected ref="approve-reject" @refresh="getAllPendingCart"></approved-or-rejected>
  </v-app>
</template>
<script>
import cartService from "@/services/cart/cartService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ApprovedOrRejected from "@/view/pages/view/school/pay/pending-invoice/ApprovedOrRejected";
import ScoreService from "@/services/score/score/ScoreService";

const accountHolder = new AccountholderService()
const cart=new cartService();
const score = new ScoreService();

export default {
  components:{
    ApprovedOrRejected,
  },
  data(){
    return{
      search:{
        info:'',
        is_active:'',
        score_id:'',
        payment_status:'pending',
      },
      isDeleting: false,
      total: null,
      perPage: null,
      page: null,
      loading:false,
      pending_invoices:[],
      status:[
        {value:'pending',text:'Pending'},
        {value:'rejected',text:'Rejected'},
      ],
      scores: [],
      total: null,
      title: '',
      perPage: null,
      page: null,
    }
  },
  methods:{
    searchPendingInvoice(){
      this.page = 1;
      this.getAllPendingCart();
    },
    getAllPendingCart(){
      this.loading = true;
      
      cart
      .getPendingInvoice(this.search,this.page)
          .then((response) => {
            this.pending_invoices=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          }).catch((err) => {

           })
          .finally(() => {
            this.loading = false;
          });
    },
    approvedCart(cartId){
      this.$refs['approve-reject'].openDialog(cartId,'approved');
    },
    rejectCart(cartId){
      this.$refs['approve-reject'].openDialog(cartId,'rejected');

    },
    viewInvoice(accountHolderId,cartId) {
      accountHolder
          .viewInvoice(accountHolderId, cartId);
    },
    getAllScore() {
        score
        .getScores(this.search)
        .then(response => {
            this.scores = response.data.scores;
        });
    },
    exportToCsv(){
      cart
      .exportInvoiceToCsv(this.search);
    },
    exportToExcel(){
      cart
      .exportInvoiceToExcel(this.search);
    }
  },
  mounted() {
    this.getAllPendingCart();
    this.getAllScore();
  }
}
</script>
